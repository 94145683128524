import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Patient from '../../Components/Patient';
import { useGoodThoughtsControllerGetPatientsForGtSessionBySessionIdQuery, useGoodThoughtsControllerGetQuestionsForGtSessionBySessionIdQuery } from '../../Features/API/apiSlice.generated';
import { useNotification } from '../../Hooks/useNotification';
import { NotificationType } from '../../Constants/notificationType.enum';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const SummaryPage = () => {
  const [loading, setLoading] = useState(false);
  const currentDate = new Date();
  const date = currentDate.toLocaleDateString();
  const time = currentDate.toLocaleTimeString();

  const {notify} = useNotification()

  const urlParams = new URLSearchParams(window.location.search);
  const participants = urlParams.get('participants');
  const sessionId:any = urlParams.get('sessionId');

  const patientIds = participants?.split(',');

  const navigate = useNavigate();

  useEffect(() => {
    notify("Please refresh the page if any patient information is missing." , NotificationType.WARNING);
 }, [])

  const { data: patients, isLoading: patientsLoading, isError: patientsError } = useGoodThoughtsControllerGetPatientsForGtSessionBySessionIdQuery({
    sessionId: sessionId
  } , {
     skip: !patientIds
  });

  const {data:sessionQuestions, isLoading: questionsLoading, isError: questionsErrors} = useGoodThoughtsControllerGetQuestionsForGtSessionBySessionIdQuery({
    sessionId: sessionId
  } , {
     skip: !patientIds
  })

  const handleHomeRoute = () => {
    navigate("/", { replace: true });
  }

  const groupOptions = {
    filename: `Group_summary_${date}.pdf`,
    page: {
      margin: 10,
      orientation: "portrait"
    }
  };
  const pdfRef = useRef(null);

  const downloadGroupPdf = async () => {
    setLoading(true);
    try {
      const pdf = new jsPDF("portrait", "pt", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      let currentY = 0;

      // Header section (Summary, date, time)
      const headerSection = document.querySelector('.summary-header');
      if (headerSection) {
        const headerCanvas = await html2canvas(headerSection as HTMLElement, {
          scale: 2,
          logging: false,
          useCORS: true,
          windowWidth: headerSection.scrollWidth,
          windowHeight: headerSection.scrollHeight,
          y: -10,
          height: (headerSection as HTMLElement).offsetHeight + 20
        });
        const headerImgData = headerCanvas.toDataURL("image/png");
        const headerHeight = (headerCanvas.height * pdfWidth) / headerCanvas.width;
        pdf.addImage(
          headerImgData,
          "PNG",
          20,
          currentY,
          pdfWidth - 40,
          headerHeight + 5
        );
        currentY += headerHeight + 10;
      }

      // Patient sections (one per page)
      const patientSections = document.querySelectorAll('.patient-section');
      for (let i = 0; i < patientSections.length; i++) {
        if (i > 0) {
          pdf.addPage();
          currentY = 0;
        }
        
        const canvas = await html2canvas(patientSections[i] as HTMLElement, {
          scale: 2,
          logging: false,
          useCORS: true
        });
        const imgData = canvas.toDataURL("image/png");
        const patientHeight = (canvas.height * pdfWidth) / canvas.width;
        
        // If first patient and won't fit on first page with header, start new page
        if (i === 0 && (currentY + patientHeight > pdfHeight)) {
          pdf.addPage();
          currentY = 0;
        }
        
        pdf.addImage(
          imgData,
          "PNG",
          0,
          currentY,
          pdfWidth,
          patientHeight
        );
      }

      // Group notes section (new page)
      const notesSection = document.querySelector('#group-notes');
      if (notesSection) {
        pdf.addPage();
        const notesCanvas = await html2canvas(notesSection as HTMLElement, {
          scale: 2,
          logging: false,
          useCORS: true
        });
        const notesImgData = notesCanvas.toDataURL("image/png");
        const notesHeight = (notesCanvas.height * pdfWidth) / notesCanvas.width;
        pdf.addImage(
          notesImgData,
          "PNG",
          20,
          50,
          pdfWidth - 40,
          notesHeight
        );
      }

      pdf.save(`Group_summary_${date}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
      notify("Error generating PDF", NotificationType.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const downloadPatientPdf = async (patientElement: HTMLElement) => {
    setLoading(true);
    try {
      const pdf = new jsPDF("portrait", "pt", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      let currentY = 0;

      // Header section
      const headerSection = document.querySelector('.summary-header');
      if (headerSection) {
        const headerCanvas = await html2canvas(headerSection as HTMLElement, {
          scale: 2,
          logging: false,
          useCORS: true,
          windowWidth: headerSection.scrollWidth,
          windowHeight: headerSection.scrollHeight,
          y: -10,
          height: (headerSection as HTMLElement).offsetHeight + 20
        });
        const headerImgData = headerCanvas.toDataURL("image/png");
        const headerHeight = (headerCanvas.height * pdfWidth) / headerCanvas.width;
        pdf.addImage(
          headerImgData,
          "PNG",
          20,
          currentY,
          pdfWidth - 40,
          headerHeight + 5
        );
        currentY += headerHeight + 10;
      }

      // Patient section
      const canvas = await html2canvas(patientElement, {
        scale: 2,
        logging: false,
        useCORS: true
      });
      const imgData = canvas.toDataURL("image/png");
      const patientHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(
        imgData,
        "PNG",
        0,
        currentY,
        pdfWidth,
        patientHeight
      );

      pdf.save(`Patient_summary_${date}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
      notify("Error generating PDF", NotificationType.ERROR);
    } finally {
      setLoading(false);
    }
  };

  if (patientsLoading) {
    return <div>Loading...</div>;
  }

  if (patientsError) {
    return <div>Error loading patients</div>;
  }

  const filteredPatients = !patientsLoading && patients && patients?.filter((patient:any) => patientIds && patientIds.includes(patient?.user));

  return (
    <>
      <div id="print-group-container" className="mx-auto w-full px-4 py-8" ref={pdfRef}>
        <div className="summary-header">
          <h1 className="text-3xl font-bold mb-4">Summary</h1>
          <p className="my-1 font-semibold"> Date: {date} </p>
          <p className="mb-4 font-semibold"> Time: {time} </p>
        </div>
        
        {filteredPatients && filteredPatients.map((patient:any) => (
          patient.answers.length > 0 && 
          <>
            <div className="patient-section" key={patient?.id}>
              <Patient 
                id={patient?.user} 
                patientAnswers={patient?.answers} 
                sessionQuestions={sessionQuestions} 
                downloadPatientPdf={downloadPatientPdf}
                loading={loading} 
              />
            </div>
          </>
        ))}
        
        <div className='flex flex-col w-full my-4' id="group-notes">
          <label className='text-lg font-semibold mb-4' title='notes'> Group Notes: </label>
          <textarea className='border border-gray-200 p-2 rounded-md' name="notes" id="notes" cols={30} rows={8}>
          </textarea>
        </div>
      </div>

      <div className='flex justify-end items-center gap-3'>
        <button
          onClick={downloadGroupPdf}
          disabled={loading}
          className={`h-16 w-[16rem] text-sm bg-primary text-white font-semibold rounded-lg py-3 px-4 my-3 ${loading && "opacity-80"}`}
        >
          {loading ? "Downloading..." : "Download Group Summary"}
        </button>
        <button onClick={handleHomeRoute} className="h-16 w-[16rem] text-sm bg-primary font-semibold text-white py-3 px-4 rounded-lg my-3 text-center">
          Go to Homepage
        </button>
      </div>
    </>
  );
};

export default SummaryPage;
