export const patientAvatarLibrary: string[] = [
  "https://models.readyplayer.me/65c2d56a5bfe15dcc69fa43b.glb",
  "https://models.readyplayer.me/65c2f244bee65ecd7a17fd8a.glb",
  "https://models.readyplayer.me/65c2f34dd58106ff3b9701ca.glb",
  "https://models.readyplayer.me/65c2f3eed66c8232a09e8418.glb",
  "https://models.readyplayer.me/65c2f490cb01177237fcf3ff.glb",
  "https://models.readyplayer.me/65c2f534f196cd6e88d4388b.glb",
  "https://models.readyplayer.me/65bfd51271a29328533a8f13.glb",
  "https://models.readyplayer.me/65bfd6cc99ee375dcfb45d1f.glb",
  "https://models.readyplayer.me/65bfd84c99ee375dcfb46610.glb",
  "https://models.readyplayer.me/65bfda3d5bfe15dcc6891488.glb",
  "https://models.readyplayer.me/65bfdb7e5bfe15dcc6891bb1.glb",
  "https://models.readyplayer.me/65bfdc757624741a0cdc0196.glb",
  "https://models.readyplayer.me/65bfed9071a29328533b19dd.glb",
  "https://models.readyplayer.me/65bfef3a5bfe15dcc68988e9.glb",
  "https://models.readyplayer.me/65bffb105bfe15dcc689cb28.glb",
  "https://models.readyplayer.me/65bffb5e7624741a0cdcad46.glb",
  "https://models.readyplayer.me/65c24855f196cd6e88ccbd7d.glb",
  "https://models.readyplayer.me/65c24a0bbee65ecd7a10add5.glb",
  "https://models.readyplayer.me/65c24918cb01177237f58842.glb",
  "https://models.readyplayer.me/65c2498499ee375dcfc4686d.glb",
  "https://models.readyplayer.me/65c24ae25bfe15dcc6991d98.glb",
  "https://models.readyplayer.me/65c24c2271a29328534ad1e8.glb",
  "https://models.readyplayer.me/65c24d2777c22d1745b9202a.glb",
  "https://models.readyplayer.me/65c24db05bfe15dcc6994686.glb",
  "https://models.readyplayer.me/65c24e3cf821534228386106.glb",
  "https://models.readyplayer.me/65c24eed71a29328534af632.glb",
  "https://models.readyplayer.me/65c51adb7299ca6a46af2d59.glb",
  "https://models.readyplayer.me/65c2f534f196cd6e88d4388b.glb",
];

export const doctorAvatarLibrary: string[] = [
  "https://models.readyplayer.me/65c24e3cf821534228386106.glb",
  "https://models.readyplayer.me/665754d8b490861c5f1bfbe4.glb",
  "https://models.readyplayer.me/65d8e94bee35cc910286cf6d.glb",
  "https://models.readyplayer.me/65d8e8077fe6ce384b65f613.glb",
  "https://models.readyplayer.me/665757d936c854537e1f8954.glb",
  "https://models.readyplayer.me/6657582b36c854537e1f8d7e.glb",
  "https://models.readyplayer.me/665758e436c854537e1f96f5.glb",
  "https://models.readyplayer.me/665762a8b490861c5f1c8b59.glb",
  "https://models.readyplayer.me/665763024e82c50acce160ae.glb",
  "https://models.readyplayer.me/670596cfb2e1e0e3fb46308f.glb",
  "https://models.readyplayer.me/67059724744a89177daefcf7.glb",
  "https://models.readyplayer.me/670597c6954244e59c3a52be.glb",
  "https://models.readyplayer.me/6705984cce89b6f364c1bbd2.glb",
  "https://models.readyplayer.me/670595ffd3e411db45b97f56.glb",
];

export const defaultAvatarProvider =
  "https://models.readyplayer.me/665757d936c854537e1f8954.glb";

export const defaultAvatarClient =
  "https://models.readyplayer.me/65bfda3d5bfe15dcc6891488.glb";

export const defaultAvatarProviderPng =
  "https://models.readyplayer.me/665757d936c854537e1f8954.png";

export const defaultAvatarClientPng =
  "https://models.readyplayer.me/65bfda3d5bfe15dcc6891488.png";
