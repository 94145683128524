import { FC, Fragment } from 'react'
import { Menu } from '@headlessui/react'
import { Button } from '../Button'
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';

interface Option {
  label: string;
  fn: () => void; 
}

type Props = {
  options : Option[]
  style?: string
  className?: string
}

const Dropdown: FC<Props> = ({ options, style, className }) => {
  return (
    <div className={`${className}`}>
      <Menu>
        <Menu.Button className={"active:scale-90"}>
          <p>
            {style === "horizontal" ? <EllipsisHorizontalIcon className='w-8 h-6' /> : <EllipsisVerticalIcon className='w-8 h-10' />}
          </p>
        </Menu.Button>
        <Menu.Items className="absolute z-10 bg-white px-4 py-3 rounded-md border shadow-md">
          {options.map((item: Option, i: number) => (
            <Menu.Item key={`dropdown_${i}`} as={Fragment}>
              {() => (
                <Button onClick={item.fn} className='my-2 w-full py-2 px-3 hover:bg-primary hover:text-white text-xs'>
                  {item.label}
                </Button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  )
}

export default Dropdown
