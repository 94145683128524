import AddNewSessionForm from "../../Components/AddNewSessionModal"
import UpdateAvatar from "../../Components/UpdateAvatar"
import Confirmation from "../../Components/ConfirmationBox"
import EditSessionForm from "../../Components/EditSessionModal"
import EditQuestionForm from "../../Components/Questions/EditQuestion"
import AddQuestionForm from "../../Components/Questions/AddQuestion"

/** Dynamic Modal Component mapping - Every component rendered by useModal hook must be defined here */
export const MODAL_MAP = {
    AddNewSessionForm,
    UpdateAvatar,
    Confirmation,
    EditSessionForm,
    EditQuestionForm,
    AddQuestionForm,
}
