import React from "react";
import { Button } from "../Button";
import Heading from "../Heading";
import { useNotification } from "../../Hooks/useNotification";
import { NotificationType } from "../../Constants/notificationType.enum";
import { Form, Formik } from "formik";
import InputField from "../InputField";
import * as Yup from "yup";
import {
  AddRpmAvatarUrlDto,
  UpdateRpmAvatarUrlDto,
  useGoodThoughtsControllerUpdateRpmAvatarUrlMutation,
  useUserControllerAddRpmAvatarUrlMutation,
} from "../../Features/API/apiSlice.generated";
import { useModal } from "../../Hooks/useModal";
import Spinner from "../Spinner";
import AvatarModelCard from "../AvatarModelCard";

const UpdateAvatar = () => {
  const initialValues = {
    Url: "",
  };
  const urlSchema = Yup.object().shape({
    Url: Yup.string().required("Url is required"),
  });
  const [addAvatartoRPMArray] = useUserControllerAddRpmAvatarUrlMutation  ()
  const [update, { isLoading }] =
    useGoodThoughtsControllerUpdateRpmAvatarUrlMutation();
  const { dismissModal } = useModal();
  const { notify } = useNotification();

  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");

  const extractAvatarInfo = (url) => {
    const regex =
      /https:\/\/(?:models\.readyplayer\.me|readyplayer\.me\/gallery)\/([a-f0-9]{20,40})/i;
    const match = url.match(regex);
    if (!match) {
      return "";
    }
    const id = match[1];
    return `https://models.readyplayer.me/${id}.glb`;
  };

  const handleUpdate = async (values) => {
    const rpmUrl = await extractAvatarInfo(values?.Url);
    try {
      const updateRpmAvatarUrlDto: UpdateRpmAvatarUrlDto = {
        rpmAvatarUrl: rpmUrl,
      };
      const addRpmAvatarUrlDto:AddRpmAvatarUrlDto = {
        rpmAvatarUrl: rpmUrl,
      };
      await addAvatartoRPMArray({addRpmAvatarUrlDto});
      await update({ updateRpmAvatarUrlDto });
      dismissModal();
      notify("Avatar updated!", NotificationType.SUCCESS);
      if (id) {
        window.location.href = `/system-settings?id=${id}`;
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      console.log(error);
      notify("Something went wrong!", NotificationType.ERROR);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center gap-3">
      <Heading level={3} className="mb-3 font-semibold">
        {" "}
        Update Avatar{" "}
      </Heading>
      <Formik
        initialValues={initialValues}
        validationSchema={urlSchema}
        onSubmit={handleUpdate}
      >
        {({ values }) => {
          const avatarUrl =
            extractAvatarInfo(values.Url)
          return (
            <>
             {avatarUrl && <AvatarModelCard type="me" key="dynamic-avatar" url={avatarUrl} />}
              <Form className="w-full flex flex-col justify-center items-center gap-3">
                <InputField
                  className="w-full border-0 rounded-lg outline-primaryLight"
                  type="text"
                  name="Url"
                  label="Url"
                  placeholder="Paste URL"
                />
                <a
                  href="/instructions"
                  target="_blank"
                  rel="noopener"
                  className="underline font-semibold py-3"
                >
                  Instructions
                </a>
                <div className="flex items-center gap-3">
                  <Button
                    onClick={dismissModal}
                    type="button"
                    className="border-secondary hover:bg-secondary hover:text-white"
                  >
                    Cancel
                  </Button>
                  <Button
                    isLoading={isLoading}
                    disabled={isLoading}
                    type="submit"
                    className="border-primaryLight hover:bg-primary hover:text-white"
                  >
                    Save
                    {isLoading && <Spinner />}
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default UpdateAvatar;
