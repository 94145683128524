import { apiSlice } from '../API/apiSlice.generated'

// this is imported into enhanceEndpoints.ts so it gets called. Otherwise, it gets tree shaken.
apiSlice.enhanceEndpoints<'User'>({
  endpoints: {
    userControllerGetCurrentUser: {
      providesTags: (result, error, arg) => [{ type: 'User' }]
    },
    goodThoughtsControllerUpdateRpmAvatarUrl : {
      invalidatesTags: () => [{type:'User'}]
    },
    goodThoughtsControllerAddRpmId : {
      invalidatesTags: () => [{type:'User'}]
    },
    userControllerAddRpmAvatarUrl :{
      invalidatesTags: () => [{type:'User'}]
    }
  }
})

export const GetUser =
  apiSlice.endpoints.userControllerGetCurrentUser.select()
