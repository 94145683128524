import React from 'react'
import { Button } from '../Button'
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline'
import { getAuth } from 'firebase/auth'

const LogoutButton = () => {

  const logout = () => {
    getAuth().signOut()
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.replace("/login");
  }

  return (
    <Button onClick={logout} className="border-0 px-1 min-w-fit focus:shadow-none">
        <ArrowRightOnRectangleIcon className="w-6 h-6 pr-1 hover:text-primary cursor-pointer" /> Logout
    </Button>
  )
}

export default LogoutButton